#pmd-dialog-root .pmd-dialog {
  padding: 1.5rem;
  width: 100% !important;
}

.pmd-radio-group {
  .pmd-radio-button-wrapper {
    & > label {
      &::before {
        min-width: 1.5rem;
      }
    }
  }
}

.pmd-radio-button-wrapper,
.pmd-radio-button-label {
  @include respond(tablet-max) {
    &::after {
      height: 12.5px !important;
      width: 12.5px !important;
      left: 4px !important;
      top: 4px !important;
    }
  }
}

.titan-checkout {
  .pmd-navbar {
    height: 3.5rem;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .05);
    -moz-box-shadow: 0 4px 4px 0 rgba(0,0,0,0.05);
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .05);

    & > div {
      align-items: center;
    }

    @include respond(desktop-max) {
      height: 3.5rem;
      align-items: stretch;

      & > div {
        flex: 0 0 calc(100% - 1.875rem);
        max-width: calc(100% - 1.875rem);
        margin: 0 1.5rem;

        .pmd-brand {
          height: 2.25rem;
        }
      }
    }

    @include respond(tablet-min) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 5.625rem;
    }
  }

  &__main {
    flex-direction: column;
    margin: 0 !important;
    width: 100%;

    @include respond(tablet-min) {
      position: fixed;
      top: 5.625rem;
      left: 0;
      bottom: 13.5rem;
      flex-direction: row;
    }

    @media screen and (min-width: 1024px) {
      bottom: 8.75rem;
    }

    @include respond(desktop-min) {
      flex: 0 0 83.33%;
      max-width: 83.33%;
      margin: 0 0 0 8.33% !important;
    }
  }

  &__sidebar,
  &__content {
    display: flex;
    flex-direction: column;

    @include respond(tablet-min) {
      height: $content-height1;
    }

    @media screen and (min-width: 1024px) {
      height: $content-height2;
    }
  }

  &__sidebar {
    background-color: $sidebar-bg;
    align-items: flex-start;
    justify-content: center;
    overflow-y: hidden;
    flex: 1;

    @include respond(tablet-min) {
      margin: 0 3rem 0 1.5rem;
      flex: 3;
    }

    @include respond(desktop-min) {
      margin: 0 4.5rem 0 0;
    }
  }

  &__content {
    justify-content: flex-start;
    overflow-y: visible;
    margin: 0 1.5rem;
    flex: 1;

    @include respond(tablet-min) {
      margin: 0 1.5rem 0 0;
      overflow-y: auto;
      flex: 4;
      padding-right: 1rem;
    }

    @include respond(desktop-min) {
      margin: 0;
    }

    & > h1 {
      margin: 1rem 0;
    }
  }

  &__footer {
    @include respond(tablet-min) {
      position: fixed !important;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @include respond(desktop-max) {
      padding-bottom: .625rem;
    }
  }
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: whitesmoke;
  border-radius: 5px;
}
