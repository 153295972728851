.content__summary {
  padding-bottom: 2rem;

  .summary {
    &__total-price {
      display: flex;

      & > div {
        flex: 1;
        align-items: center;
      }
    }

    &__submit-btn {
      width: 100%;

      @include respond(tablet-min) {
        width: auto;
      }
    }
  }

  .pmd-date-picker-wrapper {
    .react-datepicker-popper {
      left: .5px !important;
    }
  }
}
