#checkout-payment {
  .checkout-payment__payment-method {
    &:hover {
      cursor: pointer;

      .pmd-typography.pmd-typography-text {
        font-weight: bold;
      }
    }
  }
}
