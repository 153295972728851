.sidebar__order {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  width: 100%;

  @include respond(tablet-min) {
    padding: 0 1.875rem;
  }
}
