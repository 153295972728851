.main-wrapper{
  display: flex;
  
  @media screen and (max-width: 767px) {
     display: inline;
    }
  
  .maintenance-img-wrapper img {
    width: 100%;
    height: 100%;
  
    @media screen and (max-width: 767px) {
      margin-bottom: 3rem;
     }
  }

  .btn-general {
    padding: 0 30px;
  }

  .maintenance-title {
    font-size: 28px;
  }
  }