$breakpoint-mobile: 768px;
$breakpoint-tablet: 992px;
$breakpoint-desktop: 1250px;

$sidebar-bg: #ffe462;
$error-color: #cf3b3b;
$banner-color: #ffe462;

$content-height1: calc(100vh - 5.625rem - 13.5rem);
$content-height2: calc(100vh - 5.625rem - 8.75rem);
$content-withBanner-height1: calc(100vh - 5.625rem - 13.5rem - 5rem);
$content-withBanner-height2: calc(100vh - 5.625rem - 8.75rem - 5rem);
