#error-page {
  @include contentCenter();

  .error-page-content {
    align-items: center;
    flex-direction: column;

    svg {
      color: $error-color;
    }
  }
}
