.dialog-address,
.content__address-step {
  .pmd-radio-group {
    display: inline-flex;

    .pmd-radio-button-wrapper {
      &:first-of-type {
        margin-right: 1.5rem;
      }

      .pmd-radio-button-label {
        &::before {
          margin-right: .5rem !important;
        }
      }
    }
  }

  .pmd-checkbox-wrapper {
    .pmd-checkbox-label {
      align-items: center;

      &::before {
        margin-right: .5rem !important;
      }
    }
  }

  .pmd-select {
    &__options {
      left: -1px !important;
      width: calc(100% + 2px);
      top: unset !important;
      bottom: calc(100% + 0.25rem) !important;
    }
  }

  .pmd-input-wrapper {
    & ~ svg {
      font-size: 1.25rem;
      color: var(--primaryColor);
      cursor: pointer;
    }
  }
}

.dialog-address {
  .pmd-radio-button-wrapper {
    padding: 0;
  }

  .row-wrapper {
    flex-direction: column;

    .pmd-input-wrapper,
    .pmd-select {
      margin-top: 1rem !important;
    }

    .pmd-input-wrapper {
      & ~ svg {
        margin-top: 1rem;
      }
    }

    .pmd-checkbox-wrapper {
      margin-bottom: 0;
    }
  }

  .pmd-checkbox-wrapper:last-of-type {
    display: none;
  }
}

.content__address-step {
  .pmd-divider--vertical {
    height: 1.25rem;
  }

  .row-wrapper {
    flex-direction: column;

    @include respond(mobile-min) {
      flex-direction: row;

      &:last-of-type {
        .pmd-input-wrapper {
          margin: 0;
        }
      }
    }

    .pmd-input-wrapper,
    .pmd-select {
      display: flex;
      flex: 1;
      margin: 0 0 1rem 0;

      @include respond(mobile-min) {
        flex: 0 0 calc((100% - 1rem)/2);
        max-width: calc((100% - 1rem)/2);

        &:nth-of-type(2) {
          margin-left: 1rem;
        }
      }
    }
  }
}
