#mobile-app-banner {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 5rem;
  background-color: $banner-color;
  padding: 0 1.5rem;

  p {
    flex: 3;
  }

  button {
    flex: 2;
  }

  img, button {
    margin-left: 1rem;
  }

  @include respond(desktop-min) {
    margin-left: -10%;
    margin-right: -10%;
    padding-left: 10%;
  }

  @include respond(tablet-min) {
    img {
      width: 3.75rem;
      height: 3.75rem;
      margin-left: 1.25rem;
    }
  }

  @media screen and (min-width: 600px) {
    p {
      flex: unset;
    }

    button {
      flex: unset;
    }
  }
}

#thank-you-page {
  display: flex;
  flex-direction: column;
  padding: 4.5rem 0;
  width: 100%;

  .thank-you-content {
    margin: 0 1.5rem 0 1.5rem;
    order: 2;
  }

  .thank-you-image {
    float: left;
    margin-right: 1rem;

    img {
      width: 5rem;
    }
  }

  .content-cta {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 600px) {
      flex-direction: row;
    }

    .pmd-btn {
      padding: 0 2rem;

      &:first-of-type {
        margin-bottom: 1rem;

        @media screen and (min-width: 600px) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .thank-you-image {
      float: none;
      margin-right: unset;
      order: 1;
      margin-bottom: 4rem;

      img {
        width: unset;
      }
    }
  }

  @include respond(tablet-max) {
    align-items: center;
  }

  @include respond(tablet-min) {
    flex-direction: row;
    height: $content-height1;
    overflow-y: auto;

    &.with-banner {
      height: $content-withBanner-height1;
    }

    .thank-you-content {
      max-width: 35rem;
      order: 1;
    }

    .thank-you-image {
      order: 2;
      margin-bottom: 0;
    }
  }

  @include respond(desktop-min) {
    .thank-you-content {
      margin: 0 1.5rem 0 0;
    }
  }

  @media screen and (min-width: 1024px) {
    height: $content-height2;

    &.with-banner {
      height: $content-withBanner-height2;
    }
  }
}

*::-webkit-scrollbar {
  display: none;
}
