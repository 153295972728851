.bhl-checkout__footer {
  box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, .05);
  -moz-box-shadow: 0 -4px 4px 0 rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, .05);
  padding: 1rem 0;

  &-container {
    @include respond(desktop-max) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin-left: 0 !important;
      padding: 0 1.5rem !important;
    }

    @include respond(desktop-min) {
      padding: 0 !important;
    }

    .row {
      flex-direction: column;

      @include respond(tablet-min) {
        flex-direction: row;
      }
    }
  }

  &-divider {
    margin-bottom: 1rem;

    @include respond(desktop-max) {
      margin-bottom: .625rem;
    }
  }
}

.container {
  &__phone,
  &__email,
  &__ssl {
    display: flex;
    justify-content: center;

    &:not(:last-of-type) {
      margin-bottom: 1.25rem;

      @include respond(tablet-min) {
        margin-bottom: 0;
      }
    }

    @include respond(desktop-max) {
      & > div {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      & > div > div:first-of-type {
        margin: 0 0 .625rem !important;
      }

      p {
        text-align: center !important;
      }
    }

    svg {
      font-size: 1.25rem;

      @include respond(desktop-min) {
        font-size: 1.5rem;
      }
    }

    p {
      white-space: nowrap;
    }
  }

  &__phone,
  &__ssl {
    flex: 5;
  }

  &__phone {
    @include respond(tablet-min) {
      justify-content: flex-start;
    }
  }

  &__email {
    flex: 2;
  }

  &__ssl {
    @include respond(tablet-min) {
      justify-content: flex-end;
    }
  }
}
