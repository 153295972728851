html {
  user-select: none;

  @include respond(tablet-max) {
    font-size: 87.5%;
  }
}

a {
  text-decoration: none !important;
  color: var(--primaryColor) !important;

  &:hover {
    color: var(--secondaryColor) !important;
  }
}
