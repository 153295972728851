@mixin respond($breakpoint) {
  @if $breakpoint == mobile-min {
    @media screen and (min-width: $breakpoint-mobile) {
      @content
    };
  }
  @if $breakpoint == tablet-max {
    @media screen and (max-width: $breakpoint-tablet) {
      @content
    };
  }
  @if $breakpoint == tablet-min {
    @media screen and (min-width: $breakpoint-tablet) {
      @content
    };
  }
  @if $breakpoint == desktop-max {
    @media screen and (max-width: $breakpoint-desktop) {
      @content
    };
  }
  @if $breakpoint == desktop-min {
    @media screen and (min-width: $breakpoint-desktop) {
      @content
    };
  }
}

@mixin contentCenter {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
